import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import {
  ActionLinks,
  ColorScheme,
  Language,
  MainMenuItem,
  PageState,
  Picture,
  ThreeSizes,
} from "../types/index.js";
import ActionModuleNavigation from "./ActionModuleNavigation.js";
import HeaderLogo from "./HeaderLogo.js";
import LanguageMenu from "./LanguageMenu.js";
import PriorityNavigation from "./PriorityNavigation.js";

interface Props {
  actionLinks: ActionLinks;
  activePagePath: string[];
  hasHeaderImage: boolean;
  isPreview: boolean;
  languageId: Language;
  pages: PageState;
  scheme: ColorScheme;
  topLevelMainMenuItems: MainMenuItem[];
  fallbackLanguageId: Language | undefined;
  pageId: string;
  logo: Picture;
  logoSize: ThreeSizes;
  mainPageURL: string | undefined;
}

const CompactHeaderBar: FunctionComponent<Props> = ({
  actionLinks,
  logo,
  logoSize,
  activePagePath,
  fallbackLanguageId,
  pages,
  hasHeaderImage,
  topLevelMainMenuItems,
  isPreview,
  languageId,
  mainPageURL,
  pageId,
  scheme,
}) => (
  <div
    className={ClassNames(
      "CompactHeaderBar",
      style({
        backgroundColor: scheme.main.background,
        color: scheme.main.text,
      })
    )}
  >
    <HeaderLogo
      isPreview={isPreview}
      logo={logo}
      mainPageURL={mainPageURL}
      scheme={scheme}
      maxSize={{ width: 170, height: 50 }}
      size={logoSize}
    />
    <PriorityNavigation
      className="MainMenu__Wrapper--in-header-nav MainMenu__Wrapper--horizontal"
      isPreview={isPreview}
      languageId={languageId}
      menuItems={topLevelMainMenuItems}
      pages={pages}
      scheme={scheme}
      topHeaderVariant="in-header-nav"
      activePagePath={activePagePath}
      hasHeaderImage={hasHeaderImage}
      absolutePositioning={false}
      fallbackLanguageId={fallbackLanguageId}
    />
    <LanguageMenu
      isPreview={isPreview}
      languageId={languageId}
      pageId={pageId}
      scheme={scheme}
      variant="horizontal"
    />
    <ActionModuleNavigation
      isPreview={isPreview}
      actionLinks={actionLinks}
      scheme={scheme}
      showQuickLinks={true}
    />
  </div>
);

export default CompactHeaderBar;
