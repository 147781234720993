import ClassNames from "classnames";
import { FunctionComponent, useRef, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { CSSTransition } from "react-transition-group";
import { style } from "typestyle";
import { CommonHeaderLayoutProps } from "../../types/index.js";
import {
  useIntersection,
  useKeyDown,
  useScrollDirection,
} from "../../utils/hooks.js";
import {
  getEmailLink,
  getPhoneLink,
  getQuickLinksWithIcon,
  getWhatsAppLink,
  onTransitionEnd,
  overrideImagesAspectRatio,
} from "../../utils/utils.js";
import Icon from "../Icon.js";
import MainMenuListCollapsible, {
  verticalMenuClassNames,
} from "../MainMenuListCollapsible.js";
import ImagesModule from "../Modules/ImagesModule.js";
import SmartLink from "../SmartLink.js";
import CircleHamburgerButton from "./CircleHamburgerButton.js";
import MobileLanguageMenu from "./MobileLanguageMenu.js";
import MobileMainLogo from "./MobileMainLogo.js";
import QuickButtons from "./QuickButtons.js";

const Header3Mobile: FunctionComponent<CommonHeaderLayoutProps> = ({
  accommodation,
  actionLinks,
  activeModuleId,
  activePagePath,
  fallbackLanguageId,
  imagesModule: imagesModuleProp,
  isPreview,
  languageId,
  logo,
  logoSize,
  mainPageURL,
  menuItems,
  pageId,
  pages,
  queries,
  scheme,
  sticky,
  whatsAppNumber,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollDirection = useScrollDirection();
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(ref);
  const isIntersecting = intersection?.isIntersecting ?? true;
  const imagesModule = imagesModuleProp
    ? overrideImagesAspectRatio(imagesModuleProp)
    : undefined;

  useKeyDown("Escape", () => setIsMenuOpen(false));

  const isFixed =
    !isPreview &&
    ((scrollDirection === "up" && !isIntersecting) || sticky || isMenuOpen);

  const showQuickButtons =
    isPreview ||
    isIntersecting ||
    sticky ||
    isMenuOpen ||
    scrollDirection === "up";

  const quickNav = [
    getEmailLink(accommodation, languageId),
    ...(whatsAppNumber ? [getWhatsAppLink(whatsAppNumber)] : []),
    ...getQuickLinksWithIcon(actionLinks),
  ];

  const phoneLink = getPhoneLink(accommodation, languageId);

  return (
    <div className="HeaderModule__Inner--narrow">
      <header
        className={ClassNames(
          "Header3Mobile",
          `Header3Mobile--height-${logoSize}`,
          {
            "Header3Mobile--scrolled-down": !isIntersecting,
            "Header3Mobile--no-image": !imagesModule?.pageId,
          }
        )}
      >
        <div
          ref={ref}
          className={ClassNames("Header3Mobile__IntersectionHelper", {
            "Header3Mobile__IntersectionHelper--scrolled-down": !isIntersecting,
          })}
        />

        <CSSTransition
          timeout={300}
          in={isFixed}
          addEndListener={onTransitionEnd}
          classNames={{
            enter: "Header3Mobile__Bar--enter",
            enterActive: "Header3Mobile__Bar--enter-active",
            exit: "Header3Mobile__Bar--fixed Header3Mobile__Bar--exit",
            exitActive:
              "Header3Mobile__Bar--fixed Header3Mobile__Bar--exit-active",
          }}
        >
          <div
            className={ClassNames(
              "Header3Mobile__Bar",
              {
                "Header3Mobile__Bar--fixed": isFixed,
                "Header3Mobile__Bar--scrolled-down": !isIntersecting,
                "Header3Mobile__Bar--menu-open": isMenuOpen,
              },
              style({
                background: scheme.main.background,
                color: scheme.main.text,
              })
            )}
          >
            <div className="Header3Mobile__BarInner Module__Wrapper">
              <div className="Header3Mobile__MenuButtonContainer">
                <CircleHamburgerButton
                  isMenuOpen={isMenuOpen}
                  onClick={() => setIsMenuOpen((v) => !v)}
                />
              </div>
              <div className="Header3Mobile__LogoContainer">
                <MobileMainLogo
                  accommodationName={accommodation.name}
                  isPreview={isPreview}
                  logo={logo}
                  mainPageURL={mainPageURL}
                  isSmall={!isIntersecting}
                  size={logoSize}
                />
              </div>
              <div className="Header3Mobile__PhoneContainer">
                <a
                  href={phoneLink.href}
                  className="BareBtn Header3Mobile__Phone"
                  title={phoneLink.title}
                >
                  <Icon glyph={phoneLink.icon} />
                </a>
              </div>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={isMenuOpen}
          timeout={2000}
          mountOnEnter={true}
          unmountOnExit={true}
          addEndListener={onTransitionEnd}
          classNames={{
            enterActive: "Header3Mobile__Menu--open",
            enterDone: "Header3Mobile__Menu--open",
          }}
        >
          <div
            className={ClassNames(
              "Header3Mobile__Menu",
              style({
                background: scheme.main.background,
                color: scheme.main.text,
              })
            )}
          >
            <RemoveScroll
              enabled={!isPreview}
              className="Header3Mobile__MenuInner"
            >
              <MobileLanguageMenu
                className="Header3Mobile__LanguageMenu"
                isPreview={isPreview}
                languageId={languageId}
                pageId={pageId}
                onClick={() => setIsMenuOpen(false)}
              />
              <MainMenuListCollapsible
                isPreview={isPreview}
                languageId={languageId}
                onMenuItemClick={() => setIsMenuOpen(false)}
                menuItems={menuItems}
                pages={pages}
                activePagePath={activePagePath}
                fallbackLanguageId={fallbackLanguageId}
                menuLevel={0}
                collapseLevel={0}
                classNames={verticalMenuClassNames}
              />
              <ul className="Header3Mobile__QuickNav">
                {quickNav.map(({ href, icon, type }) => (
                  <li key={type} className="Header3Mobile__QuickNavItem">
                    <SmartLink
                      isPreview={isPreview}
                      anchorAttrs={{
                        href,
                        className: "Header3Mobile__QuickNavLink",
                        onClick: () => type !== "email" && setIsMenuOpen(false),
                      }}
                    >
                      <Icon
                        glyph={icon}
                        className="Header3Mobile__QuickNavIcon"
                      />
                    </SmartLink>
                  </li>
                ))}
              </ul>
            </RemoveScroll>
          </div>
        </CSSTransition>

        {imagesModule?.pageId && (
          <ImagesModule
            translatedModule={imagesModule}
            isPreview={isPreview}
            pageId={pageId}
            queries={queries}
            isActive={false}
            isInsideHeader={true}
            isFirstOnPage={false}
            parentScheme={scheme}
            activeModuleId={activeModuleId}
            fullHeight={true}
          />
        )}
      </header>

      <QuickButtons
        actionLinks={actionLinks}
        className={style({
          background: scheme.primary.background,
          color: scheme.primary.text,
        })}
        isPreview={isPreview}
        isVisible={showQuickButtons}
        onClick={() => setIsMenuOpen(false)}
      />
    </div>
  );
};

export default Header3Mobile;
